const _temp0 = require("../andrea/0_first.jpg");
const _temp1 = require("../andrea/DSC01328.jpg");
const _temp2 = require("../andrea/DSC01521.jpg");
const _temp3 = require("../andrea/pic_11.jpg");
const _temp4 = require("../andrea/pic_12.jpg");
const _temp5 = require("../andrea/pic_13.jpg");
const _temp6 = require("../andrea/pic_14.jpg");
const _temp7 = require("../andrea/pic_15.jpg");
const _temp8 = require("../andrea/pic_9.jpg");

const _temp9 = require("../andrea-2/1.jpg");
const _temp10 = require("../andrea-2/3.jpg");
const _temp11 = require("../andrea-2/4.jpg");
const _temp12 = require("../andrea-2/5.jpg");
const _temp13 = require("../andrea-2/6.jpg");
const _temp14 = require("../andrea-2/7.jpg");
const _temp15 = require("../andrea-2/8.jpg");
const _temp16 = require("../andrea-2/9.jpg");
const _temp17 = require("../andrea-2/10.jpg");
const _temp18 = require("../andrea-2/1_2.jpg");

const _temp19 = require("../arianna/pic_0.jpg");
const _temp20 = require("../arianna/pic_1.jpg");
const _temp21 = require("../arianna/pic_10.jpg");
const _temp22 = require("../arianna/pic_11.jpg");
const _temp23 = require("../arianna/pic_2.jpg");
const _temp24 = require("../arianna/pic_3.jpg");
const _temp25 = require("../arianna/pic_4.jpg");
const _temp26 = require("../arianna/pic_5.jpg");
const _temp27 = require("../arianna/pic_6.jpg");
const _temp28 = require("../arianna/pic_7.jpg");
const _temp29 = require("../arianna/pic_8.jpg");
const _temp30 = require("../arianna/pic_9.jpg");

const _temp31 = require("../bath/0_first.jpg");
const _temp32 = require("../bath/DSC04903.JPG.jpg");
const _temp33 = require("../bath/DSC04905.JPG.jpg");
const _temp34 = require("../bath/DSC04913.JPG.jpg");
const _temp35 = require("../bath/DSC04928.JPG.jpg");
const _temp36 = require("../bath/DSC05007.JPG.jpg");
const _temp37 = require("../bath/DSC05117.JPG.jpg");
const _temp38 = require("../bath/DSC05172.JPG.jpg");

const _temp39 = require("../family/0_first.jpg");
const _temp40 = require("../family/DSC05233.JPG.jpg");
const _temp41 = require("../family/DSC05254.JPG.jpg");
const _temp42 = require("../family/DSC05347.JPG.jpg");
const _temp43 = require("../family/DSC05355.JPG.jpg");
const _temp44 = require("../family/DSC05370.JPG.jpg");
const _temp45 = require("../family/DSC05418.JPG.jpg");
const _temp46 = require("../family/DSC05501.JPG.jpg");
const _temp47 = require("../family/DSC05510.JPG.jpg");
const _temp48 = require("../family/DSC05517.JPG.jpg");

const _temp49 = require("../glebik/DSC07826.jpg");
const _temp50 = require("../glebik/DSC07836.jpg");
const _temp51 = require("../glebik/DSC08026.jpg");
const _temp52 = require("../glebik/DSC08077.jpg");
const _temp53 = require("../glebik/DSC08167.jpg");
const _temp54 = require("../glebik/DSC08191.jpg");
const _temp55 = require("../glebik/DSC08386.jpg");

const _temp56 = require("../jane/0_first.jpg");
const _temp57 = require("../jane/DSC01759.JPG.sm.jpg");
const _temp58 = require("../jane/DSC01771.JPG.sm.jpg");
const _temp59 = require("../jane/DSC01907.JPG.sm.jpg");
const _temp60 = require("../jane/DSC01921.JPG.sm.jpg");
const _temp61 = require("../jane/DSC01942.JPG.sm.jpg");
const _temp62 = require("../jane/DSC02068.JPG.sm.jpg");
const _temp63 = require("../jane/DSC02081.JPG.sm.jpg");
const _temp64 = require("../jane/DSC02095.JPG.sm.jpg");
const _temp65 = require("../jane/DSC02124.JPG.sm.jpg");
const _temp66 = require("../jane/DSC02248.JPG.sm.jpg");
const _temp67 = require("../jane/DSC02287.JPG.sm.jpg");
const _temp68 = require("../jane/DSC02388.JPG.sm.jpg");
const _temp69 = require("../jane/DSC02483.JPG.sm.jpg");

const _temp70 = require("../katia/0_first.jpg");
const _temp71 = require("../katia/pic_0.jpg");
const _temp72 = require("../katia/pic_1.jpg");
const _temp73 = require("../katia/pic_2.jpg");
const _temp74 = require("../katia/pic_3.jpg");
const _temp75 = require("../katia/pic_4.jpg");
const _temp76 = require("../katia/pic_5.jpg");
const _temp77 = require("../katia/pic_7.jpg");

const _temp78 = require("../kids/0_first.jpg");
const _temp79 = require("../kids/DSC03810.JPG.jpg");
const _temp80 = require("../kids/DSC03816.JPG.jpg");
const _temp81 = require("../kids/DSC03943.JPG.jpg");
const _temp82 = require("../kids/DSC03954.JPG.jpg");
const _temp83 = require("../kids/DSC04097.JPG.jpg");
const _temp84 = require("../kids/DSC04137.JPG.jpg");
const _temp85 = require("../kids/DSC04198.JPG.jpg");
const _temp86 = require("../kids/DSC04379.JPG.jpg");
const _temp87 = require("../kids/DSC04406.JPG.jpg");
const _temp88 = require("../kids/DSC04437.JPG.jpg");
const _temp89 = require("../kids/DSC04532.JPG.jpg");
const _temp90 = require("../kids/DSC04582.JPG.jpg");
const _temp91 = require("../kids/DSC04929.JPG.jpg");
const _temp92 = require("../kids/DSC05031.JPG.jpg");
const _temp93 = require("../kids/DSC05585.JPG.jpg");

const _temp94 = require("../lera-riccardo/0_first.jpg");
const _temp95 = require("../lera-riccardo/DSC04357.jpg.jpg");
const _temp96 = require("../lera-riccardo/DSC04479.jpg.jpg");
const _temp97 = require("../lera-riccardo/DSC04495.jpg.jpg");
const _temp98 = require("../lera-riccardo/DSC04507.jpg.jpg");
const _temp99 = require("../lera-riccardo/DSC04882.jpg.jpg");

const _temp100 = require("../lera-riccardo-gubbio/0_first.jpg");
const _temp101 = require("../lera-riccardo-gubbio/DSC00691.JPG.jpg");
const _temp102 = require("../lera-riccardo-gubbio/DSC00817.JPG.jpg");
const _temp103 = require("../lera-riccardo-gubbio/DSC00835.JPG.jpg");
const _temp104 = require("../lera-riccardo-gubbio/DSC00853.JPG.jpg");
const _temp105 = require("../lera-riccardo-gubbio/DSC00883.JPG.jpg");
const _temp106 = require("../lera-riccardo-gubbio/DSC00900.JPG.jpg");
const _temp107 = require("../lera-riccardo-gubbio/DSC00905.JPG.jpg");
const _temp108 = require("../lera-riccardo-gubbio/DSC00912.JPG.jpg");
const _temp109 = require("../lera-riccardo-gubbio/DSC01019.JPG.jpg");
const _temp110 = require("../lera-riccardo-gubbio/DSC01079.JPG.jpg");
const _temp111 = require("../lera-riccardo-gubbio/DSC01153.JPG.jpg");
const _temp112 = require("../lera-riccardo-gubbio/DSC01523.JPG.jpg");

const _temp113 = require("../libri/0_first.jpg");
const _temp114 = require("../libri/DSC01507.JPG.jpg");
const _temp115 = require("../libri/DSC01509.JPG.jpg");
const _temp116 = require("../libri/DSC01519.JPG.jpg");
const _temp117 = require("../libri/DSC01525.JPG.jpg");
const _temp118 = require("../libri/DSC01558.JPG.jpg");
const _temp119 = require("../libri/DSC01564.JPG.jpg");
const _temp120 = require("../libri/DSC01593.JPG.jpg");

const _temp121 = require("../matteo/0_first.jpg");
const _temp122 = require("../matteo/pic_0.jpg");
const _temp123 = require("../matteo/pic_2.jpg");
const _temp124 = require("../matteo/pic_3.jpg");
const _temp125 = require("../matteo/pic_4.jpg");
const _temp126 = require("../matteo/pic_5.jpg");
const _temp127 = require("../matteo/pic_6.jpg");
const _temp128 = require("../matteo/pic_7.jpg");

const _temp129 = require("../natasha/0_first.jpg");
const _temp130 = require("../natasha/DSC02701.JPG.jpg");
const _temp131 = require("../natasha/DSC02803.JPG.jpg");
const _temp132 = require("../natasha/DSC02817.JPG.jpg");
const _temp133 = require("../natasha/DSC02829.JPG.jpg");
const _temp134 = require("../natasha/DSC03006.JPG.jpg");
const _temp135 = require("../natasha/DSC03070.JPG.jpg");
const _temp136 = require("../natasha/DSC03113.JPG.jpg");
const _temp137 = require("../natasha/DSC03292.JPG.jpg");
const _temp138 = require("../natasha/DSC03342.JPG.jpg");

const _temp139 = require("../roberta/0_first.jpg");
const _temp140 = require("../roberta/pic_0.jpg");
const _temp141 = require("../roberta/pic_1.jpg");
const _temp142 = require("../roberta/pic_10.jpg");
const _temp143 = require("../roberta/pic_11.jpg");
const _temp144 = require("../roberta/pic_12.jpg");
const _temp145 = require("../roberta/pic_13.jpg");
const _temp146 = require("../roberta/pic_14.jpg");
const _temp147 = require("../roberta/pic_2.jpg");
const _temp148 = require("../roberta/pic_3.jpg");
const _temp149 = require("../roberta/pic_4.jpg");
const _temp150 = require("../roberta/pic_5.jpg");
const _temp151 = require("../roberta/pic_7.jpg");
const _temp152 = require("../roberta/pic_8.jpg");
const _temp153 = require("../roberta/pic_9.jpg");

const _temp154 = require("../silvia/0_first.jpg");
const _temp155 = require("../silvia/DSC03391.JPG.jpg");
const _temp156 = require("../silvia/DSC03451.JPG.jpg");
const _temp157 = require("../silvia/DSC03462.JPG.jpg");
const _temp158 = require("../silvia/DSC03527.JPG.jpg");
const _temp159 = require("../silvia/DSC03744.JPG.jpg");
const _temp160 = require("../silvia/DSC03943.JPG.jpg");
const _temp161 = require("../silvia/DSC03998.JPG.jpg");
const _temp162 = require("../silvia/DSC04102.JPG.jpg");
const _temp163 = require("../silvia/DSC04125.JPG.jpg");
const _temp164 = require("../silvia/DSC04159.JPG.jpg");
const _temp165 = require("../silvia/DSC04280.JPG.jpg");
const _temp166 = require("../silvia/DSC04334.JPG.jpg");
const _temp167 = require("../silvia/DSC04359.JPG.jpg");

const _temp168 = require("../yulia/0_first.jpg");
const _temp169 = require("../yulia/DSC05234.JPG.jpg");
const _temp170 = require("../yulia/DSC05474.JPG.jpg");
const _temp171 = require("../yulia/DSC05608.JPG.jpg");
const _temp172 = require("../yulia/DSC05676.JPG.jpg");
const _temp173 = require("../yulia/DSC05726.JPG.jpg");
const _temp174 = require("../yulia/DSC05760.JPG.jpg");
const _temp175 = require("../yulia/DSC05770.JPG.jpg");
const _temp176 = require("../yulia/DSC05818.JPG.jpg");
const _temp177 = require("../yulia/DSC05978.JPG.jpg");
const _temp178 = require("../yulia/DSC05996.JPG.jpg");
const _temp179 = require("../yulia/DSC06037.JPG.jpg");
const _temp180 = require("../yulia/DSC06046.JPG.jpg");
const _temp181 = require("../yulia/DSC06347.JPG.jpg");

const _temp182 = require("../yulia-sasha/DSC00162.JPG.jpg");
const _temp183 = require("../yulia-sasha/DSC00171.JPG.jpg");
const _temp184 = require("../yulia-sasha/DSC00197.JPG.jpg");
const _temp185 = require("../yulia-sasha/DSC00259.JPG.jpg");
const _temp186 = require("../yulia-sasha/DSC00339.JPG.jpg");
const _temp187 = require("../yulia-sasha/DSC00413.JPG.jpg");
const _temp188 = require("../yulia-sasha/DSC00556.JPG.jpg");
const _temp189 = require("../yulia-sasha/DSC00749.JPG.jpg");
const _temp190 = require("../yulia-sasha/DSC00789.JPG.jpg");
const _temp191 = require("../yulia-sasha/DSC09831.JPG.jpg");
const _temp192 = require("../yulia-sasha/DSC09844.JPG.jpg");
const _temp193 = require("../yulia-sasha/DSC09849.JPG.jpg");
const _temp194 = require("../yulia-sasha/DSC09980.JPG.jpg");

module.exports = {
  "andrea":   {
    "0_first": _temp0,
    "DSC01328": _temp1,
    "DSC01521": _temp2,
    "pic_11": _temp3,
    "pic_12": _temp4,
    "pic_13": _temp5,
    "pic_14": _temp6,
    "pic_15": _temp7,
    "pic_9": _temp8
  },
  "andrea-2":   {
    "1": _temp9,
    "3": _temp10,
    "4": _temp11,
    "5": _temp12,
    "6": _temp13,
    "7": _temp14,
    "8": _temp15,
    "9": _temp16,
    "10": _temp17,
    "1_2": _temp18
  },
  "arianna":   {
    "pic_0": _temp19,
    "pic_1": _temp20,
    "pic_10": _temp21,
    "pic_11": _temp22,
    "pic_2": _temp23,
    "pic_3": _temp24,
    "pic_4": _temp25,
    "pic_5": _temp26,
    "pic_6": _temp27,
    "pic_7": _temp28,
    "pic_8": _temp29,
    "pic_9": _temp30
  },
  "bath":   {
    "0_first": _temp31,
    "DSC04903.JPG": _temp32,
    "DSC04905.JPG": _temp33,
    "DSC04913.JPG": _temp34,
    "DSC04928.JPG": _temp35,
    "DSC05007.JPG": _temp36,
    "DSC05117.JPG": _temp37,
    "DSC05172.JPG": _temp38
  },
  "family":   {
    "0_first": _temp39,
    "DSC05233.JPG": _temp40,
    "DSC05254.JPG": _temp41,
    "DSC05347.JPG": _temp42,
    "DSC05355.JPG": _temp43,
    "DSC05370.JPG": _temp44,
    "DSC05418.JPG": _temp45,
    "DSC05501.JPG": _temp46,
    "DSC05510.JPG": _temp47,
    "DSC05517.JPG": _temp48
  },
  "glebik":   {
    "DSC07826": _temp49,
    "DSC07836": _temp50,
    "DSC08026": _temp51,
    "DSC08077": _temp52,
    "DSC08167": _temp53,
    "DSC08191": _temp54,
    "DSC08386": _temp55
  },
  "jane":   {
    "0_first": _temp56,
    "DSC01759.JPG.sm": _temp57,
    "DSC01771.JPG.sm": _temp58,
    "DSC01907.JPG.sm": _temp59,
    "DSC01921.JPG.sm": _temp60,
    "DSC01942.JPG.sm": _temp61,
    "DSC02068.JPG.sm": _temp62,
    "DSC02081.JPG.sm": _temp63,
    "DSC02095.JPG.sm": _temp64,
    "DSC02124.JPG.sm": _temp65,
    "DSC02248.JPG.sm": _temp66,
    "DSC02287.JPG.sm": _temp67,
    "DSC02388.JPG.sm": _temp68,
    "DSC02483.JPG.sm": _temp69
  },
  "katia":   {
    "0_first": _temp70,
    "pic_0": _temp71,
    "pic_1": _temp72,
    "pic_2": _temp73,
    "pic_3": _temp74,
    "pic_4": _temp75,
    "pic_5": _temp76,
    "pic_7": _temp77
  },
  "kids":   {
    "0_first": _temp78,
    "DSC03810.JPG": _temp79,
    "DSC03816.JPG": _temp80,
    "DSC03943.JPG": _temp81,
    "DSC03954.JPG": _temp82,
    "DSC04097.JPG": _temp83,
    "DSC04137.JPG": _temp84,
    "DSC04198.JPG": _temp85,
    "DSC04379.JPG": _temp86,
    "DSC04406.JPG": _temp87,
    "DSC04437.JPG": _temp88,
    "DSC04532.JPG": _temp89,
    "DSC04582.JPG": _temp90,
    "DSC04929.JPG": _temp91,
    "DSC05031.JPG": _temp92,
    "DSC05585.JPG": _temp93
  },
  "lera-riccardo":   {
    "0_first": _temp94,
    "DSC04357.jpg": _temp95,
    "DSC04479.jpg": _temp96,
    "DSC04495.jpg": _temp97,
    "DSC04507.jpg": _temp98,
    "DSC04882.jpg": _temp99
  },
  "lera-riccardo-gubbio":   {
    "0_first": _temp100,
    "DSC00691.JPG": _temp101,
    "DSC00817.JPG": _temp102,
    "DSC00835.JPG": _temp103,
    "DSC00853.JPG": _temp104,
    "DSC00883.JPG": _temp105,
    "DSC00900.JPG": _temp106,
    "DSC00905.JPG": _temp107,
    "DSC00912.JPG": _temp108,
    "DSC01019.JPG": _temp109,
    "DSC01079.JPG": _temp110,
    "DSC01153.JPG": _temp111,
    "DSC01523.JPG": _temp112
  },
  "libri":   {
    "0_first": _temp113,
    "DSC01507.JPG": _temp114,
    "DSC01509.JPG": _temp115,
    "DSC01519.JPG": _temp116,
    "DSC01525.JPG": _temp117,
    "DSC01558.JPG": _temp118,
    "DSC01564.JPG": _temp119,
    "DSC01593.JPG": _temp120
  },
  "matteo":   {
    "0_first": _temp121,
    "pic_0": _temp122,
    "pic_2": _temp123,
    "pic_3": _temp124,
    "pic_4": _temp125,
    "pic_5": _temp126,
    "pic_6": _temp127,
    "pic_7": _temp128
  },
  "natasha":   {
    "0_first": _temp129,
    "DSC02701.JPG": _temp130,
    "DSC02803.JPG": _temp131,
    "DSC02817.JPG": _temp132,
    "DSC02829.JPG": _temp133,
    "DSC03006.JPG": _temp134,
    "DSC03070.JPG": _temp135,
    "DSC03113.JPG": _temp136,
    "DSC03292.JPG": _temp137,
    "DSC03342.JPG": _temp138
  },
  "roberta":   {
    "0_first": _temp139,
    "pic_0": _temp140,
    "pic_1": _temp141,
    "pic_10": _temp142,
    "pic_11": _temp143,
    "pic_12": _temp144,
    "pic_13": _temp145,
    "pic_14": _temp146,
    "pic_2": _temp147,
    "pic_3": _temp148,
    "pic_4": _temp149,
    "pic_5": _temp150,
    "pic_7": _temp151,
    "pic_8": _temp152,
    "pic_9": _temp153
  },
  "silvia":   {
    "0_first": _temp154,
    "DSC03391.JPG": _temp155,
    "DSC03451.JPG": _temp156,
    "DSC03462.JPG": _temp157,
    "DSC03527.JPG": _temp158,
    "DSC03744.JPG": _temp159,
    "DSC03943.JPG": _temp160,
    "DSC03998.JPG": _temp161,
    "DSC04102.JPG": _temp162,
    "DSC04125.JPG": _temp163,
    "DSC04159.JPG": _temp164,
    "DSC04280.JPG": _temp165,
    "DSC04334.JPG": _temp166,
    "DSC04359.JPG": _temp167
  },
  "yulia":   {
    "0_first": _temp168,
    "DSC05234.JPG": _temp169,
    "DSC05474.JPG": _temp170,
    "DSC05608.JPG": _temp171,
    "DSC05676.JPG": _temp172,
    "DSC05726.JPG": _temp173,
    "DSC05760.JPG": _temp174,
    "DSC05770.JPG": _temp175,
    "DSC05818.JPG": _temp176,
    "DSC05978.JPG": _temp177,
    "DSC05996.JPG": _temp178,
    "DSC06037.JPG": _temp179,
    "DSC06046.JPG": _temp180,
    "DSC06347.JPG": _temp181
  },
  "yulia-sasha":   {
    "DSC00162.JPG": _temp182,
    "DSC00171.JPG": _temp183,
    "DSC00197.JPG": _temp184,
    "DSC00259.JPG": _temp185,
    "DSC00339.JPG": _temp186,
    "DSC00413.JPG": _temp187,
    "DSC00556.JPG": _temp188,
    "DSC00749.JPG": _temp189,
    "DSC00789.JPG": _temp190,
    "DSC09831.JPG": _temp191,
    "DSC09844.JPG": _temp192,
    "DSC09849.JPG": _temp193,
    "DSC09980.JPG": _temp194
  }
}