[andrea]
  date = '09/2020'
  inst = 'https://www.instagram.com/p/CCQHp9gJvEp/'
[andrea-2]
  date = '11/2021'
  inst = 'https://www.instagram.com/p/CCQHp9gJvEp/'
[arianna]
  date = '08/2020'
  inst = 'https://www.instagram.com/p/CCxvfoWJLpM/'
[bath]
  date = '03/2021'
  inst = 'https://www.instagram.com/p/CPY8GK-JIP6/'
[family]
  date = '11/2021'
  inst = 'https://www.instagram.com/p/CXdea5ZME0g/'
[glebik]
  date = '09/2020'
  inst = 'https://www.instagram.com/p/CFz8JQHJ9MT/'
[jane]
  date = '08/2021'
  inst = 'https://www.instagram.com/p/CTJqJaUsslR/'
[katia]
  date = '08/2020'
  inst = 'https://www.instagram.com/p/CE_PcGQJdwb/'
[kids]
  date = '08/2021'
  inst = 'https://www.instagram.com/p/CTmjCPFsz1Y/'
[lera-riccardo]
  date = '03/2021'
  inst = 'https://www.instagram.com/p/CPSwSSOJzie/'
[lera-riccardo-gubbio]
  date = '10/2021'
  inst = 'https://www.instagram.com/p/CVLbdyjspst/'
[libri]
  date = '08/2021'
  inst = 'https://www.instagram.com/p/CTHFlRpMdsI/'
[matteo]
  date = '10/2020'
  inst = 'https://www.instagram.com/p/CEEjjXopTwi/'
[natasha]
  date = '10/2021'
  inst = 'https://www.instagram.com/p/CV6Ccq9sF57/'
[roberta]
  date = '07/2020'
  inst = 'https://www.instagram.com/p/CHS2fQapJRf/'
[silvia]
  date = '10/2021'
  inst = 'https://www.instagram.com/p/CWQ65m5M6DA/'
[yulia]
  date = '07/2021'
  inst = 'https://www.instagram.com/p/CRElAHCM-ki/'
[yulia-sasha]
  date = '08/2021'
  inst = 'https://www.instagram.com/p/CS1wfKKM1Zu/'
